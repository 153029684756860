import React from "react";
import Layout from "../layouts/page";

export default function Success() {
  return (
    <Layout path="/">
      <div className="row">
        <div className="col-12 col-md-6 p-5 pl-5">
          <img src="/contact.png" className="img-fluid" alt="contact me" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 px-5">
          <p>
            Thanks for getting in touch! I will get back to you as soon as
            possible.
          </p>
        </div>
      </div>
    </Layout>
  );
}
